$body-bg: #282C34;
$sidebar-bg: #373C47;
$body-color: #FFF;

@import '~bootstrap/scss/bootstrap.scss';

.App {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;

  main {
    width: 100%;
    padding: 10px;

    .page-not-found {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        margin-bottom: 20px;
      }
    }
  }

  .sidebar {
    background-color: $sidebar-bg;
    width: 200px;

    .header-top {
      color: #FFF;
      border-bottom: solid 1px;
      padding-bottom: 1rem;

      img {
        width: 150px;
        height: 150px;
      }

      nav {
        width: 100%;
      }
    }
  }
}

.list-group .list-group-item {
  background-color: transparent;
}

.page-search {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding-top: 20px;

  .page-search-input {
    padding: 10px;
    width: 500px;
  }
}
